import { config } from '@fortawesome/fontawesome-svg-core'

import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

import { useEffect, useRef } from 'react';
import { faLanguage as farLanguage } from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkers from '../../../helpers/class/Checkers';
import ContactIcons from '../partials/ContactIcons';
import DropdownArrow from '../partials/DropdownArrow';
import SearchIcon  from '../partials/SearchIcon'
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../helpers/MethodHelper";
import {current} from "immer";
import Link from "next/link";

export default function HeaderMenu ( props ) {

  const menuItemDisableWrap = props.hasOwnProperty('menuItemDisableWrap') ? props.menuItemDisableWrap : false

  const handleHide = props.hasOwnProperty('handleHide') ? props.handleHide : false

  const textWrapClass = menuItemDisableWrap ? '' : 'text-nowrap'

  const showMenuItems = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_show_menu_items')
  );

  const elRefs = useRef([]);

  useEffect(() => {
    if (document !== undefined && elRefs !== undefined) {
      for (const elRef of elRefs.current) {
        elRef !== undefined && elRef.addEventListener('mouseenter', function () {
          this.children[0].classList.add('show');
          this.children[0].setAttribute('aria-extended', 'true');
          this.children[1].classList.add('show');
        })
        elRef !== undefined && elRef.addEventListener('mouseleave', function () {
          this.children[0].classList.remove('show');
          this.children[0].setAttribute('aria-extended', 'false');
          this.children[1].classList.remove('show');
        })
      }

    }
  }, [elRefs]);

  const topLevelHref = (menu, loggiaUrl) => {
    if (menu.hasOwnProperty('redirectUrl')) {
      return menu.redirectUrl
    }

    return loggiaUrl != null ? props.referer + loggiaUrl : menu.urlSlug != null ? props.referer + menu.urlSlug : '#'
  }

  const topLevelHrefBlank = (menu, loggiaUrl) => {
    if (menu.hasOwnProperty('redirectUrl')) {
      return true
    }

    return false
  }

  return (
      <>
        {
            showMenuItems == '1' && Checkers.isValidArray(props.menu) &&
            props.menu.map((menu, key) => {
              let loggiaUrl = menu.loggiaUrl;
              if (Checkers.isValidString(loggiaUrl) && loggiaUrl.startsWith('/')) {
                loggiaUrl = loggiaUrl.substring(1);
              }

              let special_class = ''
              if (menu.special_class != undefined && menu.special_class.length > 0) {
                special_class = menu.special_class
              }

              return (
                  menu.title != null ?
                      <li
                          key={key}
                          ref={Checkers.isValidArray(menu.entries) ? (el) => {
                            elRefs.current[key] = el
                          } : undefined}
                          className={menu.entries != null ? 'nav-item dropdown cursor-pointer' : 'nav-item'}
                      >
                        {
                          menu.hasOwnProperty('force_reload') && menu.force_reload ?
                              <>
                                <a aria-current="page"
                                   href={topLevelHref(menu, loggiaUrl)}
                                   className={special_class + " " + (menu.entries != null ? 'd-flex nav-link dropdown-toggle ' + textWrapClass : 'nav-link test-111 ' + textWrapClass)}>
                                  {menu.title}
                                  {Checkers.isValidArray(menu.entries) &&
                                      <FontAwesomeIcon icon={fasChevronDown} className="m-l-5 align-self-center"
                                                       style={{fontSize: '10.5px'}}/>
                                  }
                                </a>
                              </>
                              :
                              <>
                                {
                                  topLevelHrefBlank(menu, loggiaUrl) ?
                                      <>
                                        <Link target={"_blank"}
                                              href={topLevelHref(menu, loggiaUrl)}
                                        >
                                          <a className={special_class + " " + (menu.entries != null ? 'd-flex nav-link dropdown-toggle ' + textWrapClass : 'nav-link test-222 ' + textWrapClass)}
                                             aria-current="page"
                                             onClick={() => {
                                               if (handleHide!=undefined) {
                                                 handleHide()
                                               }
                                             }}
                                             rel={"noreferrer noopener"}>
                                            {menu.title}
                                            {Checkers.isValidArray(menu.entries) &&
                                                <FontAwesomeIcon icon={fasChevronDown} className="m-l-5 align-self-center"
                                                                 style={{fontSize: '10.5px'}}/>
                                            }
                                          </a>
                                        </Link>
                                      </>
                                      :
                                      <>
                                        <Link href={topLevelHref(menu, loggiaUrl)}>
                                          <a aria-current="page"
                                             onClick={() => {
                                               if (handleHide!=undefined) {
                                                 handleHide()
                                               }
                                             }}
                                             className={special_class + " " + (menu.entries != null ? 'd-flex nav-link dropdown-toggle ' + textWrapClass : 'nav-link test-44444 ' + textWrapClass)}>
                                            {menu.title}
                                            {Checkers.isValidArray(menu.entries) &&
                                                <FontAwesomeIcon icon={fasChevronDown} className="m-l-5 align-self-center"
                                                                 style={{fontSize: '10.5px'}}/>
                                            }
                                          </a>
                                        </Link>
                                      </>
                                }
                              </>
                        }
                        {Checkers.isValidArray(menu.entries) &&
                            <ul className={props.modalDropdownClass != null ? 'dropdown-menu dropdown-article-menu\ ' + props.modalDropdownClass : 'dropdown-menu dropdown-article-menu shadow ' + (menu.entries.length>10 ? "megamenu" : "")}>
                              {
                                  props.modalDropdownClass == null &&
                                  <DropdownArrow/>
                              }
                              {
                                menu.entries.map((entry, key) => {
                                  let loggiaUrl = entry.loggiaUrl;
                                  if (Checkers.isValidString(loggiaUrl) && loggiaUrl.startsWith('/')) {
                                    loggiaUrl = loggiaUrl.substring(1);
                                  }

                                  return (
                                      <li
                                          key={key}
                                          className={key == 0 ? 'dropdown-ul-item mt-20px' : 'dropdown-ul-item mt-3'}
                                      >
                                        <Link href={loggiaUrl != null ? props.referer + loggiaUrl : entry.urlSlug != null ? props.referer + entry.urlSlug : '#'} >
                                          <a  className={"dropdown-item text-wrap "} onClick={() => {
                                            if (handleHide!=undefined) {
                                              handleHide()
                                            }
                                          }}>
                                            {entry.title}
                                          </a>
                                        </Link>
                                      </li>
                                  )
                                })
                              }
                            </ul>
                        }
                      </li>
                      : ''
              )
            })
        }
        {
            <ContactIcons contactIcons={props.contactIcons}/>
        }
        {
          props.partials !== null && props.partials.hasOwnProperty('book') === true ?
              props.partials.book != null && props.headerPartialBook == true ?
                  <li className="nav-item" dangerouslySetInnerHTML={{__html: props.partials.book.title}}/>
                  : ''
              : ''
        }
        {
            props.partials !== null && props.partials.hasOwnProperty('lang') === true ?
              props.partials.lang != null ?
                  <li className="nav-item dropdown cursor-pointer">
                    <Link href={'#'} id={props.modalDropdownClass != null ? 'modal-lang' : 'lang'}
                       className={props.partials.lang.entries != null ? 'd-flex nav-link lang-toggle' : 'nav-link'}
                       aria-current="page"
                       aria-label="lang" title="lang"
                          onClick={() => {
                            if (handleHide!=undefined) {
                              handleHide()
                            }
                          }}
                    >
                      <FontAwesomeIcon icon={farLanguage} size="2x"/>
                    </Link>
                    <ul className={props.modalDropdownClass != null ? 'dropdown-menu dropdown-lang-menu\ ' + props.modalDropdownClass : 'dropdown-menu dropdown-lang-menu shadow'}
                        aria-labelledby={props.modalDropdownClass != null ? 'modal-lang' : 'lang'}>
                      {
                          props.modalDropdownClass == null &&
                          <DropdownArrow/>
                      }
                      {props.partials.lang.entries.map((entry, key) => (
                          <li className={key == 0 ? 'dropdown-ul-item mt-20px' : 'dropdown-ul-item mt-3'} key={key}>
                            <Link  href={props.referer + entry.code}>
                              <a className="dropdown-item" onClick={() => {
                                if (handleHide!=undefined) {
                                  handleHide()
                                }
                              }}>{entry.name}</a>
                            </Link>
                          </li>
                      ))}
                    </ul>
                  </li>
                  : ''
              : ''
        }
      </>
  )
}
